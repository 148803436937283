import React, { Component } from 'react'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'
import Layout from "../components/Layout"
import SEO from "../components/Seo"
// import Button from "../components/Button"
import Legend from "../components/Legend"
import { Grid, Row, Col } from 'react-flexbox-grid'
// import ScrollIntoView from 'react-scroll-into-view'
import ReactFullpage from '@fullpage/react-fullpage';
import CountUp from 'react-countup';
import Fade from 'react-reveal/Fade';

import logoFull from "../assets/svg/logo-full.svg"
import scrollDown from "../assets/svg/scroll-down.svg"
import scrollTop from "../assets/svg/scroll-top.svg"
import toggle from "../assets/svg/toggle.svg"
import bgEsper from "../assets/svg/bg-esperluette.svg"
import bgEsperHome from "../assets/svg/bg-esperluette-home.svg"
import pastImplan from "../assets/svg/pastilleJaune.svg"
import pastInter from "../assets/svg/pastilleMarron.svg"
import mail from "../assets/svg/mail.svg"
import mailwhite from "../assets/svg/mail-white.svg"

import { injectIntl, Link } from "gatsby-plugin-intl"

import SVGMap1200 from "../images/carte_1200px.svg"
import SVGMap1200Mobile from "../images/carte_1200px_mobile.svg"

// import TooltipVenice from "../components/MapTooltipsVenice.js"
// import TooltipTokyo from "../components/MapTooltipsTokyo.js"
// import ControlledPopup from "../components/ControlledPopup.js"
// import { ZoneParis, ZoneVenise, ZoneTokyo} from "../components/MapZones.js"

// import Fade from 'react-reveal/Fade'

// import Map from "../components/Map"


class IndexPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      toggleNicolas: false,
      toggleMathieu: false,
      toggleIsabella: false,
      fullpageIsFirst: true,
      fullpageIsLast: false
    };
  }

  // componentDidMount() {
  //   window.fullpage_api.fitToSection();
  // }

  onLeave(origin, destination, direction) {
    console.log("origin",origin.item);
    console.log("destination",destination.item);


    this.setState({
      fullpageIsFirst : destination.isFirst,
      fullpageIsLast : destination.isLast
    })  
  }

  render() {
    const { intl } = this.props; // add more props here as neede
    return (
      <Layout>
        <SEO siteTitle={intl.formatMessage({ id: "meta.title" })} siteDescription={intl.formatMessage({ id: "meta.description" })} />


        <ReactFullpage
          //fullpage options
          licenseKey = {'YOUR_KEY_HERE'}
          scrollingSpeed = {400}
          // fixedElements = ".scrollto"
          fitToSection = {false}
          verticalCentered = {false}
          autoScrolling = {false}
          bigSectionsDestination = 'top'
          onLeave={this.onLeave.bind(this)}
          // afterRender={this.afterRender.bind(this)}

          render={({ state, fullpageApi }) => {
            return (
              
              <>
              <ReactFullpage.Wrapper>
                <section className="section section-wrapper fp-noscroll fp-auto-height fp-auto-height-responsive" id="home" data-anchor="fp-home" data-name="Home"> 
                  <Img className="background-image full desktop" fluid={this.props.data.home.childImageSharp.fluid} />
                  <Img className="background-image full mobile" fluid={this.props.data.home_mobile.childImageSharp.fluid} />
                  <Img className="background-image full ipad" fluid={this.props.data.home_mobile.childImageSharp.fluid} />
                  <Grid fluid>
                    <Row>
                      <Col xs={12} >
                        <Fade delay={200}>
                          <img className="logo-full" src={logoFull} alt="" />
                        </Fade>
                        <div className="slogan">
                          <Fade delay={200}>
                            {intl.formatMessage({ id: "home.slogan1" })}{intl.formatMessage({ id: "home.slogan2" })}
                          </Fade>
                          <div className="slogan-lines">
                            <div className="left-line"></div>
                            <div className="right-line"></div>
                            <div className="bottom-line"></div>
                          </div>
                          <img className="bg-esper home" src={bgEsperHome} alt="" />
                        </div>
                        
                      </Col>
                    </Row>
                  </Grid>
                  <Legend text="Luanda Bay - Angola"/>
                </section>

                <section className="section section-wrapper section-corporate post fp-auto-height fp-auto-height-responsive" id="presentation" data-anchor="fp-presentation">
                  <img className="bg-esper desktop" src={bgEsper} alt="" />
                  
                  <div className="background-image half-right">
                    <Img className="desktop" fluid={this.props.data.presentation.childImageSharp.fluid} />
                    <Img className="mobile" fluid={this.props.data.presentation_mobile.childImageSharp.fluid} />
                    <Img className="ipad" fluid={this.props.data.presentation_mobile.childImageSharp.fluid} />
                  </div>
                  
                  
                  <Grid fluid>
                    <Row className="hat">
                      <Col mdOffset={1} md={11} xs={12} >
                        <Fade delay={200}>
                          <p className="categorie">{intl.formatMessage({ id: "presentation.cat" })}</p>
                          <h1>{intl.formatMessage({ id: "presentation.title" })}</h1>
                          <h2>{intl.formatMessage({ id: "presentation.subtitle" })}</h2>
                        </Fade>
                      </Col>
                    </Row>
                    <Row className="content">
                      <Col mdOffset={1} md={10} lg={4} xs={12} >
                        {/* <img className="bg-esper mobile" src={bgEsper} alt="" /> */}
                        <Fade delay={200}>
                          <p>{intl.formatMessage({ id: "presentation.bloc1" })}</p>
                          <p><strong>{intl.formatMessage({ id: "presentation.bloc2" })}</strong></p>
                          <p>{intl.formatMessage({ id: "presentation.bloc3" })}</p>
                        </Fade>
                      </Col>
                    </Row>
                  </Grid>
                  <Legend text={intl.formatMessage({ id: "presentation.legend" })} />
                </section>
                
                <section className="section section-wrapper section-corporate post fp-auto-height fp-auto-height-responsive" id="expertises" data-anchor="fp-expertises">
                  <img className="bg-esper desktop" src={bgEsper} alt="" />
                  <div className="background-image half-right">
                    <Img className="desktop" fluid={this.props.data.expertises.childImageSharp.fluid} />
                    <Img className="mobile" fluid={this.props.data.expertises_mobile.childImageSharp.fluid} />
                    <Img className="ipad" fluid={this.props.data.expertises_mobile.childImageSharp.fluid} />
                  </div>
                  <Grid fluid>
                    <Row className="hat">
                      <Col mdOffset={1} md={9} xs={12} >
                        <Fade delay={200}>
                          <p className="categorie">{intl.formatMessage({ id: "expertise.cat" })}</p>
                          <h1>{intl.formatMessage({ id: "expertise.title" })}</h1>
                        </Fade>
                      </Col>
                    </Row>
                    <Row className="content">
                      <Col mdOffset={1} md={10} xs={12} lg={4}>
                        <img className="bg-esper mobile" src={bgEsper} alt="" />
                        <Fade delay={200}>
                          <p><strong>{intl.formatMessage({ id: "expertise.bloc1" })}</strong></p>
                          <p>{intl.formatMessage({ id: "expertise.bloc2" })}</p>
                          <p>{intl.formatMessage({ id: "expertise.bloc3" })}</p>
                        </Fade>
                      </Col>
                    </Row>
                  </Grid>
                  <Legend text={intl.formatMessage({ id: "expertise.legend" })} />
                </section>

                <section className="section section-wrapper whiteSection" id="offers-header" data-anchor="fp-offers-header">
                  <Img className="background-image full desktop" fluid={this.props.data.ocean.childImageSharp.fluid} />
                  <Img className="background-image full mobile" fluid={this.props.data.ocean_mobile.childImageSharp.fluid} />
                  <Img className="background-image full ipad" fluid={this.props.data.ocean_ipad.childImageSharp.fluid} />
                  <Grid fluid>
                    <Row>
                      <Col mdOffset={1} md={10} xs={12}>
                        <div className="section-header">
                          <Fade delay={500}>
                            <div className="header-content">
                              <h1>{intl.formatMessage({ id: "offers.title" })}</h1>
                              <p>{intl.formatMessage({ id: "offers.subtitle" })}</p>
                              <ul>
                                <li><span className="underline-effect" onClick={() => window.fullpage_api.silentMoveTo("fp-intelligence-compliance")}>Intelligence & Compliance</span></li>
                                <li><span className="underline-effect" onClick={() => window.fullpage_api.silentMoveTo("fp-business-diplomacy")}>Business Diplomacy</span></li>
                                <li><span className="underline-effect" onClick={() => window.fullpage_api.silentMoveTo("fp-public-affairs-reputation")}>Public Affairs & Reputation</span></li>
                                <li><span className="underline-effect" onClick={() => window.fullpage_api.silentMoveTo("fp-sovereign-dispute-resolution")}>Sovereign Dispute Resolution</span></li>
                              </ul>
                            </div>
                          </Fade>
                        </div>
                      </Col>
                    </Row>
                  </Grid>
                </section>
                  
                
                <section className="section section-wrapper section-offers post fp-auto-height fp-auto-height-responsive" id="offer-intelligence" data-anchor="fp-intelligence-compliance">
                  <img className="bg-esper desktop" src={bgEsper} alt="" />
                  <div className="background-image half-right">
                    <Img className="desktop" fluid={this.props.data.intelligence.childImageSharp.fluid} />
                    <Img className="mobile" fluid={this.props.data.intelligence_mobile.childImageSharp.fluid} />
                    <Img className="ipad" fluid={this.props.data.intelligence_ipad.childImageSharp.fluid} />
                  </div>
                  <Grid fluid>
                    <Row className="hat">
                      <Col mdOffset={1} md={10} xs={12}>
                      <Fade delay={200}>
                        <h1>Intelligence & Compliance</h1>
                      </Fade>
                      </Col>
                    </Row>
                    <Row className="content">
                      <Col mdOffset={1} md={10} xs={12} lg={4}>
                        <Fade delay={200}>
                          <p>{intl.formatMessage({ id: "intelligence.bloc1" })}</p>
                          <p>{intl.formatMessage({ id: "intelligence.bloc2" })}</p>
                          <Fade delay={500} duration={500} distance={'1em'} mirror right cascade>
                            <ul>
                                <li>{intl.formatMessage({ id: "intelligence.list.item1" })}</li>
                                <li>{intl.formatMessage({ id: "intelligence.list.item2" })}</li>
                                <li>{intl.formatMessage({ id: "intelligence.list.item3" })}</li>
                                <li>{intl.formatMessage({ id: "intelligence.list.item4" })}</li>
                                <li>{intl.formatMessage({ id: "intelligence.list.item5" })}</li>
                            </ul>
                          </Fade>
                          <p>{intl.formatMessage({ id: "intelligence.bloc3" })}</p>
                          <Fade delay={500} duration={500} distance={'1em'} mirror right cascade>
                            <ul>
                                <li>{intl.formatMessage({ id: "intelligence.list2.item1" })}</li>
                                <li>{intl.formatMessage({ id: "intelligence.list2.item2" })}</li>
                                <li>{intl.formatMessage({ id: "intelligence.list2.item3" })}</li>
                                <li>{intl.formatMessage({ id: "intelligence.list2.item4" })}</li>
                            </ul>
                          </Fade>
                        </Fade>
                      </Col>
                    </Row>
                  </Grid>
                  <Legend text={intl.formatMessage({ id: "intelligence.legend" })} />
                </section>

                <section className="section section-wrapper section-offers post fp-auto-height fp-auto-height-responsive" id="offer-diplomacy" data-anchor="fp-business-diplomacy">
                  <img className="bg-esper desktop" src={bgEsper} alt="" />
                  <div className="background-image half-right">
                    <Img className="desktop" fluid={this.props.data.diplomacy.childImageSharp.fluid} />
                    <Img className="mobile" fluid={this.props.data.diplomacy_mobile.childImageSharp.fluid} />
                    <Img className="ipad" fluid={this.props.data.diplomacy_ipad.childImageSharp.fluid} />
                  </div>
                  <Grid fluid>
                    <Row className="hat">
                      <Col mdOffset={1} md={10} xs={12}>
                        <Fade delay={200}>
                          <h1>Business Diplomacy</h1>
                        </Fade>
                      </Col>
                    </Row>
                    <Row className="content">
                      <Col mdOffset={1} md={10} xs={12} lg={4}>
                        <Fade delay={200}>
                          <p>{intl.formatMessage({ id: "diplomacy.bloc1" })}</p>
                          <p>{intl.formatMessage({ id: "diplomacy.bloc2" })}</p>
                          <Fade delay={500} duration={500} distance={'1em'} mirror right cascade>
                            <ul>
                              <li>{intl.formatMessage({ id: "diplomacy.list.item1" })}</li>
                              <li>{intl.formatMessage({ id: "diplomacy.list.item2" })}</li>
                              <li>{intl.formatMessage({ id: "diplomacy.list.item3" })}</li>
                              <li>{intl.formatMessage({ id: "diplomacy.list.item4" })}</li>
                              <li>{intl.formatMessage({ id: "diplomacy.list.item5" })}</li>
                              <li>{intl.formatMessage({ id: "diplomacy.list.item6" })}</li>
                              <li>{intl.formatMessage({ id: "diplomacy.list.item7" })}</li>
                            </ul>
                          </Fade>
                        </Fade>
                      </Col>
                    </Row>
                  </Grid>
                  <Legend text={intl.formatMessage({ id: "diplomacy.legend" })} />
                </section>

                <section className="section section-wrapper section-offers post fp-auto-height fp-auto-height-responsive" id="offer-reputation" data-anchor="fp-public-affairs-reputation">
                  <img className="bg-esper desktop" src={bgEsper} alt="" />
                  <div className="background-image half-right">
                    <Img className="desktop" fluid={this.props.data.reputation.childImageSharp.fluid} />
                    <Img className="mobile" fluid={this.props.data.reputation_mobile.childImageSharp.fluid} />
                    <Img className="ipad" fluid={this.props.data.reputation_ipad.childImageSharp.fluid} />
                  </div>
                  <Grid fluid>
                    <Row className="hat">
                      <Col mdOffset={1} md={10} xs={12}>
                        <Fade delay={200}>
                          <h1>Public Affairs & Reputation</h1>
                        </Fade>
                      </Col>
                    </Row>
                    <Row className="content">
                      <Col mdOffset={1} md={10} xs={12} lg={4}>
                        <Fade delay={200}>
                          <p>{intl.formatMessage({ id: "reputation.bloc1" })}</p>
                          <p>{intl.formatMessage({ id: "reputation.bloc2" })}</p>
                          <Fade delay={500} duration={500} distance={'1em'} mirror right cascade>
                            <ul>
                              <li>{intl.formatMessage({ id: "reputation.list.item1" })}</li>
                              <li>{intl.formatMessage({ id: "reputation.list.item2" })}</li>
                              <li>{intl.formatMessage({ id: "reputation.list.item3" })}</li>
                              <li>{intl.formatMessage({ id: "reputation.list.item4" })}</li>
                              <li>{intl.formatMessage({ id: "reputation.list.item5" })}</li>
                              <li>{intl.formatMessage({ id: "reputation.list.item6" })}</li>
                            </ul>
                          </Fade>
                        </Fade>
                      </Col>
                    </Row>
                  </Grid>
                  <Legend text={intl.formatMessage({ id: "reputation.legend" })} />
                </section>

                <section className="section section-wrapper section-offers post fp-auto-height fp-auto-height-responsive" id="offer-consulting" data-anchor="fp-sovereign-dispute-resolution">
                  <img className="bg-esper desktop" src={bgEsper} alt="" />
                  <div className="background-image half-right">
                    <Img className="desktop" fluid={this.props.data.dispute.childImageSharp.fluid} />
                    <Img className="mobile" fluid={this.props.data.dispute_mobile.childImageSharp.fluid} />
                    <Img className="ipad" fluid={this.props.data.dispute_ipad.childImageSharp.fluid} />
                  </div>
                  <Grid fluid>
                    <Row className="hat">
                      <Col mdOffset={1} md={10} xs={12}>
                        <Fade delay={200}>
                          <h1>Sovereign Dispute Resolution</h1>
                        </Fade>
                      </Col>
                    </Row>
                    <Row className="content">
                      <Col mdOffset={1} md={10} xs={12} lg={4}>
                        <Fade delay={200}>
                          <p>{intl.formatMessage({ id: "consulting.bloc1" })}</p>
                          <p>{intl.formatMessage({ id: "consulting.bloc2" })}</p>
                          <p>{intl.formatMessage({ id: "consulting.bloc3" })}</p>
                          <Fade delay={500} duration={500} distance={'1em'} mirror right cascade>
                            <ul>
                              <li>{intl.formatMessage({ id: "consulting.list.item1" })}</li>
                              <li>{intl.formatMessage({ id: "consulting.list.item2" })}</li>
                              <li>{intl.formatMessage({ id: "consulting.list.item3" })}</li>
                              <li>{intl.formatMessage({ id: "consulting.list.item4" })}</li>
                              <li>{intl.formatMessage({ id: "consulting.list.item5" })}</li>
                              <li>{intl.formatMessage({ id: "consulting.list.item6" })}</li>
                            </ul>
                          </Fade>
                        </Fade>
                      </Col>
                    </Row>
                  </Grid>
                  <Legend text={intl.formatMessage({ id: "consulting.legend" })} />
                </section>

                <section className="section section-wrapper fp-noscroll fp-auto-height fp-auto-height-responsive" id="international" data-anchor="fp-international">
          
                  <Grid fluid>
                    <Row middle="sm">
                      <Col mdOffset={1} md={10} xs={12} lg={3}>
                        <Fade delay={200}>
                          <p className="categorie">
                            {intl.formatMessage({ id: "map.cat" })}
                            <img className="bg-esper mobile" src={bgEsper} alt="" />
                            <img className="bg-esper ipad" src={bgEsper} alt="" />
                          </p>
                          <p>{intl.formatMessage({ id: "map.bloc1" })}</p>
                          <div className="legendMapWrapper">
                            <div className="legendMAp">
                              <img className="" src={pastInter} alt="" />
                              <p>{intl.formatMessage({ id: "map.implantation" })}</p>
                            </div>
                            <div className="legendMAp">
                              <img className="" src={pastImplan} alt="" />
                              <p>{intl.formatMessage({ id: "map.intervention" })}</p>
                            </div>
                          </div>
                        </Fade>
                      </Col>
                      <Col mdOffset={1} lgOffset={0} md={10} xs={12} lg={8}>
                        <Fade delay={200}>
                          <div className="mapwrapper">
                            <img className="desktop" src={SVGMap1200} alt="" />
                            <img className="mobile" src={SVGMap1200Mobile} alt="" />
                          </div>
                        </Fade>
                      </Col>
                    </Row>
                    
                    <Row className="tooltip-wrapper">
                      <Col mdOffset={1} md={10} xs={12}>
                        <Grid fluid>
                          <Row>
                            <Col xs={12} xl={4} className="tooltip-col">
                              <Fade delay={200}>
                                <div className="tooltip">
                                  <h2>{intl.formatMessage({ id: "tooltip.paris.title" })}</h2>
                                  <p>{intl.formatMessage({ id: "tooltip.paris.description" })}</p>
                                  <a className="tooltip-contact-link" href="mailto:paris@bucy-associates.com">
                                    <img src={mail} alt="" />
                                    {intl.formatMessage({ id: "tooltip.paris.contact" })}
                                  </a>
                                </div>
                              </Fade>
                            </Col>
                            <Col xs={12} xl={4} className="tooltip-col">
                              <Fade delay={200}>
                                <div className="tooltip">
                                  <h2>{intl.formatMessage({ id: "tooltip.venice.title" })}</h2>
                                  <p>{intl.formatMessage({ id: "tooltip.venice.description" })}</p>
                                  <a className="tooltip-contact-link" href="mailto:venezia@bucy-associates.com">
                                    <img src={mail} alt="" />
                                    {intl.formatMessage({ id: "tooltip.venice.contact" })}
                                  </a>
                                </div>
                              </Fade>
                            </Col>
                            <Col xs={12} xl={4} className="tooltip-col">
                              <Fade delay={200}>
                                <div className="tooltip">
                                  <h2>{intl.formatMessage({ id: "tooltip.tokyo.title" })}</h2>
                                  <p>{intl.formatMessage({ id: "tooltip.tokyo.description" })}</p>
                                  <a className="tooltip-contact-link" href="mailto:tokyo@bucy-associates.com">
                                    <img src={mail} alt="" />
                                    {intl.formatMessage({ id: "tooltip.tokyo.contact" })}
                                  </a>
                                </div>
                              </Fade>
                            </Col>
                          </Row>
                        </Grid>
                      </Col>
                    </Row>
                  </Grid>
                </section>

                <section className="section section-wrapper fp-auto-height fp-auto-height-responsive" id="management" data-anchor="fp-management">
                  <div className="section-header" id="management-header">
                    <Fade delay={200}>
                      <img className="bg-esper desktop" src={bgEsper} alt="" />
                      <img className="bg-esper mobile" src={bgEsper} alt="" />
                      <img className="bg-esper ipad" src={bgEsper} alt="" />
                      <div className="header-content">
                        <h1>{intl.formatMessage({ id: "management.title" })}</h1>
                      </div>
                    </Fade>
                  </div>
                  <Grid fluid>
                    <Row>
                      <Col lgOffset={1} lg={10} xs={12}>
                        <Grid fluid>
                          <Row>
                            <Col xs={12} lg={4} className="manager">
                              <Fade delay={200}>
                                <Img fluid={this.props.data.bourillon.childImageSharp.fluid} />
                                <h2>Nicolas<br/>Bourillon</h2>
                              </Fade>
                                {this.state.toggleNicolas ? 
                                  <>
                                    <p>{intl.formatMessage({ id: "nicolas.bloc1" })}</p>
                                    <p>{intl.formatMessage({ id: "nicolas.bloc2" })}</p>
                                    <p>{intl.formatMessage({ id: "nicolas.bloc3" })}</p>
                                    <p>{intl.formatMessage({ id: "nicolas.bloc4" })}</p>
                                    <p>{intl.formatMessage({ id: "nicolas.bloc5" })}</p>
                                  </>
                                : 
                                  null
                                }
                                {!this.state.toggleNicolas ?
                                <Fade delay={200}>
                                  <div className="readmore">
                                    <button className="btn-2" onClick={() => this.setState({ toggleNicolas: !this.state.toggleNicolas })}>
                                      {intl.formatMessage({ id: "readmore" })}
                                      <svg xmlns="http://www.w3.org/2000/svg" width="12.807" height="21.58" viewBox="0 0 12.807 21.58">
                                        <g id="Groupe_458" data-name="Groupe 458" transform="translate(11.393 1.414) rotate(90)">
                                          <line id="Ligne_6" data-name="Ligne 6" y1="9.98" x2="9.377" fill="none" stroke="#000" stroke-linecap="round" stroke-width="2"/>
                                          <line id="Ligne_7" data-name="Ligne 7" x2="9.377" y2="9.98" transform="translate(9.377)" fill="none" stroke="#000" stroke-linecap="round" stroke-width="2"/>
                                        </g>
                                      </svg>
                                    </button>
                                  </div>
                                </Fade>
                                :
                                <div className="readless">
                                  <strong><a onClick={() => {fullpageApi.fitToSection();this.setState({ toggleNicolas: !this.state.toggleNicolas }); }}>{intl.formatMessage({ id: "readless" })}</a></strong>
                                  <img className="toggle" src={toggle} alt="" />
                                </div>
                                }
                              
                            </Col>
                            <Col xs={12} lg={4} className="manager">
                              <Fade delay={200}>
                                <Img fluid={this.props.data.gelmann.childImageSharp.fluid} />
                                <h2>Matthieu<br/>Gelmann</h2>
                              </Fade>
                              {this.state.toggleMathieu ? 
                                <>
                                  <p>{intl.formatMessage({ id: "matthieu.bloc1" })}</p>
                                  <p>{intl.formatMessage({ id: "matthieu.bloc2" })}</p>
                                  <p>{intl.formatMessage({ id: "matthieu.bloc3" })}</p>
                                  <p>{intl.formatMessage({ id: "matthieu.bloc4" })}</p>
                                  <p>{intl.formatMessage({ id: "matthieu.bloc5" })}</p>
                                </>
                              : 
                                null
                              }
                              
                              {!this.state.toggleMathieu ?
                                <Fade delay={200}>
                                  <button className="btn-2" onClick={() => this.setState({ toggleMathieu: !this.state.toggleMathieu })}>
                                    {intl.formatMessage({ id: "readmore" })}
                                    <svg xmlns="http://www.w3.org/2000/svg" width="12.807" height="21.58" viewBox="0 0 12.807 21.58">
                                      <g id="Groupe_458" data-name="Groupe 458" transform="translate(11.393 1.414) rotate(90)">
                                        <line id="Ligne_6" data-name="Ligne 6" y1="9.98" x2="9.377" fill="none" stroke="#000" stroke-linecap="round" stroke-width="2"/>
                                        <line id="Ligne_7" data-name="Ligne 7" x2="9.377" y2="9.98" transform="translate(9.377)" fill="none" stroke="#000" stroke-linecap="round" stroke-width="2"/>
                                      </g>
                                    </svg>
                                  </button>
                                </Fade>
                                :
                                <div className="readless">
                                  <strong><a onClick={() => {fullpageApi.fitToSection();this.setState({ toggleMathieu: !this.state.toggleMathieu })}}>{intl.formatMessage({ id: "readless" })}</a></strong>
                                  <img className="toggle" src={toggle} alt="" />
                                </div>
                              }
                            </Col>
                            <Col xs={12} lg={4} className="manager">
                              <Fade delay={200}>
                              <Img fluid={this.props.data.bourgeois.childImageSharp.fluid} />
                              <h2>Isabella<br/>Le Bourgeois</h2>
                              </Fade>
                              {this.state.toggleIsabella ? 
                                <>
                                  <p>{intl.formatMessage({ id: "isabella.bloc1" })}</p>
                                  <p>{intl.formatMessage({ id: "isabella.bloc2" })}</p>
                                  <p>{intl.formatMessage({ id: "isabella.bloc3" })}</p>
                                  <p>{intl.formatMessage({ id: "isabella.bloc4" })}</p>
                                  <p>{intl.formatMessage({ id: "isabella.bloc5" })}</p>
                                  <p>{intl.formatMessage({ id: "isabella.bloc6" })}</p>
                                </>
                              : 
                                null
                              }
                              
                              {!this.state.toggleIsabella ?
                                <Fade delay={200}>
                                  <button className="btn-2" onClick={() => this.setState({ toggleIsabella: !this.state.toggleIsabella })}>
                                    {intl.formatMessage({ id: "readmore" })}
                                    <svg xmlns="http://www.w3.org/2000/svg" width="12.807" height="21.58" viewBox="0 0 12.807 21.58">
                                      <g id="Groupe_458" data-name="Groupe 458" transform="translate(11.393 1.414) rotate(90)">
                                        <line id="Ligne_6" data-name="Ligne 6" y1="9.98" x2="9.377" fill="none" stroke="#000" stroke-linecap="round" stroke-width="2"/>
                                        <line id="Ligne_7" data-name="Ligne 7" x2="9.377" y2="9.98" transform="translate(9.377)" fill="none" stroke="#000" stroke-linecap="round" stroke-width="2"/>
                                      </g>
                                    </svg>
                                  </button>
                                </Fade>
                                :
                                <div className="readless">
                                  <strong><a onClick={() => {fullpageApi.fitToSection();this.setState({ toggleIsabella: !this.state.toggleIsabella })}}>{intl.formatMessage({ id: "readless" })}</a></strong>
                                  <img className="toggle" src={toggle} alt="" />
                                </div>
                              }
                            </Col>
                          </Row>
                        </Grid>
                      </Col>
                    </Row>
                  </Grid>
                </section>

                <section className="section section-wrapper fp-auto-height fp-auto-height-responsive" id="advisory-board" data-anchor="fp-advisory-board">
                  <div className="section-header" id="advisory-board-header">
                    <Fade delay={200}>
                      <img className="bg-esper desktop" src={bgEsper} alt="" />
                      <img className="bg-esper ipad" src={bgEsper} alt="" />
                      <img className="bg-esper mobile" src={bgEsper} alt="" />
                      <div className="header-content">
                        <h1>{intl.formatMessage({ id: "advisory.title" })}</h1>
                        <p>{intl.formatMessage({ id: "advisory.bloc1" })}</p>
                        <p>{intl.formatMessage({ id: "advisory.bloc2" })}</p>
                      </div>
                    </Fade>
                  </div>
                  {/* <Grid fluid>
                    <Row>
                      <Col lgOffset={1} lg={10} xs={12}>
                        <Grid fluid>
                          <Row>
                            <Col md={12} lg={5} className="board-card-img hidden-md hidden-sm hidden-xs">
                              <Img fluid={this.props.data.chambon.childImageSharp.fluid} />
                            </Col>
                            <Col md={12} lg={7} className="board-card-text">
                              <h2 className="hidden-lg hidden-xl ">Jean-Louis Chambon</h2>
                              <p>{intl.formatMessage({ id: "jean-louis.bloc1" })}</p>
                              <p>{intl.formatMessage({ id: "jean-louis.bloc2" })}</p>
                              <p>{intl.formatMessage({ id: "jean-louis.bloc3" })}</p>
                              <p>{intl.formatMessage({ id: "jean-louis.bloc4" })}</p>
                              <p>{intl.formatMessage({ id: "jean-louis.bloc5" })}</p>
                            </Col>
                          </Row>
                        </Grid>
                      </Col>
                    </Row>
                  </Grid> */}
                </section>

                <section className="section section-wrapper fp-auto-height fp-auto-height-responsive" id="at-a-glance" data-anchor="fp-at-a-glance">
                  
                  <Grid fluid>
                    <Row>
                      <Col lgOffset={1} lg={10} xs={12}>
                        <Grid fluid>
                          <Row center="xs">
                            <div className="section-header" id="at-a-glance-header">
                              <Fade delay={200}>
                                <img className="bg-esper desktop" src={bgEsper} alt="" />
                                <img className="bg-esper ipad" src={bgEsper} alt="" />
                                <img className="bg-esper mobile" src={bgEsper} alt="" />
                                <div className="header-content">
                                  <h1>{intl.formatMessage({ id: "glance.title" })}</h1>
                                </div>
                              </Fade>
                            </div>
                          </Row>
                          <Row center="xl" className="glance-wrapper">
                            <Col xl={3} xs={12} className="glance-col">
                              <Fade delay={200}>
                                <div className="glance-label">{intl.formatMessage({ id: "glance.col1.label1" })}</div>
                                <div className="glance-value"><span className="glance-number">{intl.formatMessage({ id: "glance.col1.value" })} </span> {intl.formatMessage({ id: "glance.col1.suffix" })}</div>
                              </Fade>
                            </Col>
                            <Col xl={3} xs={12} className="glance-col">
                              <Fade delay={300}>
                                <div className="glance-label">{intl.formatMessage({ id: "glance.col2.label1" })}</div>
                                <div className="glance-value"><span className="glance-number">{intl.formatMessage({ id: "glance.col2.value" })} </span> {intl.formatMessage({ id: "glance.col2.suffix" })}</div>
                              </Fade>
                            </Col>
                            <Col xl={3} xs={12} className="glance-col">
                              <Fade delay={400}>
                                <div className="glance-label">{intl.formatMessage({ id: "glance.col3.label1" })}</div>
                                <div className="glance-value"><span className="glance-number">{intl.formatMessage({ id: "glance.col3.value" })} </span> {intl.formatMessage({ id: "glance.col3.suffix" })}</div>
                                <div className="glance-label">{intl.formatMessage({ id: "glance.col3.label2" })}</div>
                              </Fade>
                            </Col>
                            <Col xl={3} xs={12} className="glance-col">
                              <Fade delay={500}>
                                <div className="glance-label">{intl.formatMessage({ id: "glance.col4.label1" })}</div>
                                <div className="glance-value">{intl.formatMessage({ id: "glance.col4.prefix" })}<span className="glance-number"> {intl.formatMessage({ id: "glance.col4.value" })} </span> {intl.formatMessage({ id: "glance.col4.suffix" })}</div>
                                <div className="glance-label">{intl.formatMessage({ id: "glance.col4.label2" })}</div>
                              </Fade>
                            </Col>
                            
                          </Row>
                        </Grid>
                      </Col>
                    </Row>
                  </Grid>
                </section>

                <section className="section section-wrapper whiteSection fp-noscroll fp-auto-height fp-auto-height-responsive" id="contact" data-anchor="fp-contact">
                <Img className="background-image full" fluid={this.props.data.contact.childImageSharp.fluid} />
                <Img className="background-image full mobile" fluid={this.props.data.contactmobile.childImageSharp.fluid} />
                <Grid fluid>
                  <Row>
                    <Col mdOffset={1} xs={12} >
                      <Fade delay={200}>
                        <p className="categorie">Contact</p>
                        <h2>Head Office</h2>
                        <p>Bucy & Associates<br />47, rue de l’Université<br />75007 Paris<br />{intl.formatMessage({ id: "contact.country" })}</p>
                        <br />
                        <br />
                        <br />
                        <a className="contact-link" href="mailto:frontdesk@bucy-associates.com">
                          <img src={mailwhite} alt="" />
                          {intl.formatMessage({ id: "contact.mail" })}
                        </a>
                        {/* <p>
                          <Link to="/legal-notices">Mentions Légales</Link>
                          <br />
                          <Link to="/credits">Crédits</Link>
                        </p> */}
                      </Fade>
                    </Col>
                  </Row>
                </Grid>
                <Legend text={intl.formatMessage({ id: "contact.legend" })} />
              </section>
              </ReactFullpage.Wrapper>

              <a className={this.state.fullpageIsLast ? "scrollto scrolldown hidden-xs hidden-sm hidden-md hidden-lg hidden-xl" : "scrollto scrolldown" } onClick={() => fullpageApi.moveSectionDown()} onMouseOver={() => console.log(window.fullpage_api.getActiveSection())}>
                <img src={scrollDown} alt="" />
                {/* <span>{window.fullpage_api.getActiveSection().item.dataset.name}</span> */}
              </a>
              <a className={this.state.fullpageIsFirst ? "scrollto scrolltop hidden-xs hidden-sm hidden-md hidden-lg hidden-xl" : "scrollto scrolltop" } onClick={() => fullpageApi.moveSectionUp()}>
                <img src={scrollTop} alt="" />
              </a>
              
            </>
            );
          }}
        />
        
      </Layout>
    )
  }
}

export default injectIntl(IndexPage)

export const fluidImage = graphql`
fragment fluidImage on File {
  childImageSharp {
    fluid(maxWidth: 2500) {
      ...GatsbyImageSharpFluid
    }
  }
}
`;

export const pageQuery = graphql`
  query {
    home: file(relativePath: { eq: "home.jpg" }) {
      ...fluidImage
    },
    home_mobile: file(relativePath: { eq: "home_mobile.jpg" }) {
      ...fluidImage
    },
    presentation: file(relativePath: { eq: "presentation.jpg" }) {
      ...fluidImage
    },
    presentation_mobile: file(relativePath: { eq: "presentation_mobile.jpg" }) {
      ...fluidImage
    },
    intelligence: file(relativePath: { eq: "intelligence.jpg" }) {
      ...fluidImage
    },
    intelligence_mobile: file(relativePath: { eq: "intelligence_mobile.jpg" }) {
      ...fluidImage
    },
    intelligence_ipad: file(relativePath: { eq: "intelligence_ipad.jpg" }) {
      ...fluidImage
    },
    expertises: file(relativePath: { eq: "expertises.jpg" }) {
      ...fluidImage
    },
    expertises_mobile: file(relativePath: { eq: "expertises_mobile.jpg" }) {
      ...fluidImage
    },
    expertises_ipad: file(relativePath: { eq: "expertises_ipad.jpg" }) {
      ...fluidImage
    },
    diplomacy: file(relativePath: { eq: "diplomacy.jpg" }) {
      ...fluidImage
    },
    diplomacy_mobile: file(relativePath: { eq: "diplomacy_mobile.jpg" }) {
      ...fluidImage
    },
    diplomacy_ipad: file(relativePath: { eq: "diplomacy_ipad.jpg" }) {
      ...fluidImage
    },
    reputation: file(relativePath: { eq: "reputation.jpg" }) {
      ...fluidImage
    },
    reputation_mobile: file(relativePath: { eq: "reputation_mobile.jpg" }) {
      ...fluidImage
    },
    reputation_ipad: file(relativePath: { eq: "reputation_ipad.jpg" }) {
      ...fluidImage
    },
    dispute: file(relativePath: { eq: "dispute.jpg" }) {
      ...fluidImage
    },
    dispute_mobile: file(relativePath: { eq: "dispute_mobile.jpg" }) {
      ...fluidImage
    },
    dispute_ipad: file(relativePath: { eq: "dispute_ipad.jpg" }) {
      ...fluidImage
    },
    ocean: file(relativePath: { eq: "ocean.jpg" }) {
      ...fluidImage
    },
    ocean_mobile: file(relativePath: { eq: "ocean_mobile.jpg" }) {
      ...fluidImage
    },
    ocean_ipad: file(relativePath: { eq: "ocean_ipad.jpg" }) {
      ...fluidImage
    },
    mozambique: file(relativePath: { eq: "mozambique.jpg" }) {
      ...fluidImage
    },
    contact: file(relativePath: { eq: "contact.jpg" }) {
      ...fluidImage
    },
    contactmobile: file(relativePath: { eq: "contactmobile.jpg" }) {
      ...fluidImage
    },
    map: file(relativePath: { eq: "carte.jpg" }) {
      ...fluidImage
    },
    mapmobile: file(relativePath: { eq: "carte_mobile.jpg" }) {
      ...fluidImage
    },
    bourillon: file(relativePath: { eq: "bourillon.jpg" }) {
      ...fluidImage
    },
    gelmann: file(relativePath: { eq: "gelmann.jpg" }) {
      ...fluidImage
    },
    bourgeois: file(relativePath: { eq: "bourgeois.jpg" }) {
      ...fluidImage
    },
    chambon: file(relativePath: { eq: "chambon.jpg" }) {
      ...fluidImage
    },
  }
`;